<template>
    <div class="link-to-video">
        <!-- Link -->
        <div class="link-to-video__link-container" v-if="!videoIsOpened && videoLinkData.pictureLink">
            <a class="link-to-video__link" :href="videoLinkData.videoLink" @click="linkClickHandler">
                <!-- picture -->
                <img class="link-to-video__link-picture" :src="videoLinkData.pictureLink.url"
                     :alt="videoLinkData.pictureLink.alt">
                <!-- play-icon -->
                <div class="link-to-video__play-icon-container"
                     :class="{ 'link-to-video__play-icon-container_is-more-opacity': playIconIsOpacity }">
                    <!-- svg -->
                    <svg class="link-to-video__play-icon" xmlns="http://www.w3.org/2000/svg" width="82" height="82"
                         viewBox="0 0 82 82" fill="none">
                        <g opacity="0.8">
                            <path
                                d="M81 41C81 63.0914 63.0914 81 41 81C18.9086 81 1 63.0914 1 41C1 18.9086 18.9086 1 41 1C63.0914 1 81 18.9086 81 41Z"
                                fill="white" fill-opacity="0.4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M41 2C19.4609 2 2 19.4609 2 41C2 62.5391 19.4609 80 41 80C62.5391 80 80 62.5391 80 41C80 19.4609 62.5391 2 41 2ZM0 41C0 18.3563 18.3563 0 41 0C63.6437 0 82 18.3563 82 41C82 63.6437 63.6437 82 41 82C18.3563 82 0 63.6437 0 41Z"
                                  fill="white"/>
                            <path
                                d="M33.3086 33.5792V48.4208C33.3086 49.9342 34.9243 50.8992 36.2568 50.1817L50.0383 42.7609C51.4407 42.0058 51.4407 39.9942 50.0383 39.2391L36.2568 31.8183C34.9243 31.1008 33.3086 32.0659 33.3086 33.5792Z"
                                fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M36.0197 32.2585C35.0204 31.7204 33.8086 32.4442 33.8086 33.5792V48.4208C33.8086 49.5558 35.0204 50.2796 36.0197 49.7415L49.8012 42.3207C50.853 41.7544 50.853 40.2457 49.8012 39.6793L36.0197 32.2585ZM32.8086 33.5792C32.8086 31.6875 34.8283 30.4812 36.4938 31.378L50.2753 38.7988C52.0283 39.7427 52.0283 42.2573 50.2753 43.2012L36.4938 50.622C34.8283 51.5188 32.8086 50.3125 32.8086 48.4208V33.5792Z"
                                  fill="white"/>
                        </g>
                    </svg>
                </div>
            </a>
        </div>
        <!-- Video -->
        <div class="link-to-video__video-container" id="video-container" v-if="videoIsOpened"
             :style="{ 'height': `${videoHeight}px` }">
            <iframe
                class="link-to-video__video"
                width="100%"
                :height="videoHeight"
                :src="hrefToFrame"
                :title="videoLinkData.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            >
            </iframe>
        </div>
        <!-- 
            Разрешение данной картинки: 1280 х 720
         -->
    </div>
</template>

<script>
export default {
    name: "LinkToVideo",
    props: {
        videoLinkData: {
            type: Object,
            required: true,
            // default() {
            //     return {
            //         title: "Цифровое сканирование зубов у детей",
            //         videoLink: "https://www.youtube.com/watch?v=dcXdcw23sIA",
            //         pictureLink : { url: require("./../assets/icons/link-to-video/test-link-picture.jpg"), alt: "Link picture" }
            //     };
            // }
        },
    },
    data() {
        return {
            // data
            hashCode: "",
            hrefBase: "https://www.youtube.com/embed/",
            autoplayParam: "autoplay=1",
            videoHeight: 700,
            // flags
            videoIsOpened: false,
            playIconIsOpacity: false
        };
    },
    computed: {
        hrefToFrame() {
            return this.hrefBase + this.hashCode + "?" + this.autoplayParam;
        }
    },
    methods: {
        getVideoHashCode() {
            if (this.videoLinkData.videoLink) {
                const EQUAL_SYMBOL = "=";

                let equalSymbolPosition = this.videoLinkData.videoLink.indexOf(EQUAL_SYMBOL);
                let hashCode = this.videoLinkData.videoLink.slice(equalSymbolPosition + 1);

                this.hashCode = hashCode;
            }
        },
        linkClickHandler(event) {
            this.setVideoSize();
            event.preventDefault();
            this.openVideo();
        },
        setVideoSize() {
            const linkPicture = document.querySelector(".link-to-video__link-picture");
            let linkPictureHeight = parseFloat(getComputedStyle(linkPicture).height);

            this.videoHeight = linkPictureHeight;
        },
        openVideo() {
            this.playIconIsOpacity = true;

            let delay = 400;
            setTimeout(() => {
                this.videoIsOpened = true;
                this.playIconIsOpacity = false;
            }, delay);
        },
    },
    async mounted() {
        await this.getVideoHashCode();
        await this.setVideoSize();

        window.addEventListener('resize', this.setVideoSize);
    }
}
</script>

<style>

</style>