<template>
    <LinkToVideo :videoLinkData="videoLinkData"/>
</template>

<script>
import LinkToVideo from "./../../../components/LinkToVideo.vue";

export default {
    name: 'App',
    components: {
        LinkToVideo
    },
    data() {
        return {
            // # Link to video data
            // ## video link data
            videoLinkData: {
                // title: "Цифровое сканирование зубов у детей",
                // videoLink: "https://www.youtube.com/watch?v=dcXdcw23sIA",
                // pictureLink: {
                //     url: require("./../../../assets/icons/link-to-video/test-link-picture.jpg"),
                //     alt: "Link picture"
                // }
            }
        }
    },
    async created() {
        if (window.videoLinkData) {
            this.videoLinkData = {...window.videoLinkData}
        }
    }
}
</script>

<style lang="scss">
@import "./../../../assets/sass/core/ui.scss";
</style>
